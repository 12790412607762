import "intersection-observer";
import "./sliders.js";
import sal from "sal.js";

// loadClass
const theApp = document.getElementById("theApp");
theApp.classList.add("is-loaded");

// LazyLoad Images
window.addEventListener("load", (event) => {
  // Observer
  const inViewElms = document.querySelectorAll(".inViewJs");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(
      (entry) => {
        const lazyImage = entry.target.querySelector("img[data-src]");
        if (entry.intersectionRatio > 0) {
          // console.log(lazyImage.querySelector("img[data-src]"));
          lazyImage.src = lazyImage.dataset.src;

          lazyImage.onload = function () {
            entry.target.classList.add("inViewJs-active");
            observer.unobserve(entry.target);
          };
        } else {
          // console.log("out of view");
          // entry.target.classList.remove("inViewJs-active");
        }
      },
      { rootMargin: "0px 0px 100px 0px" }
    );
  });

  inViewElms.forEach((elm) => {
    observer.observe(elm);
  });
});

// OVERLAY MENU
let closeMenu = document.getElementById("closeMenu"),
  menuOverlay = document.querySelector(".menuOverlay"),
  menuBgOverlay = document.querySelector(".menuOverlay .menuOverlay-menuBG"),
  menuCloseOverlay = document.querySelector(
    ".menuOverlay .menuOverlay-close p"
  );
if (closeMenu) {
  closeMenu.addEventListener("click", () => {
    menuOverlay.classList.add("is-active");
  });
  menuBgOverlay.addEventListener("click", () => {
    menuOverlay.classList.remove("is-active");
  });
  menuCloseOverlay.addEventListener("click", () => {
    menuOverlay.classList.remove("is-active");
  });
}


// initiativeOverlay
let initiativeButton = document.getElementById("initiativeButton"),
  initiativeOverlay = document.getElementById("initiativeOverlay"),
  initiativeOverlayBGClose = document.querySelector(
    ".initiativeOverlay .initiativeOverlay-bg"
  ),
  initiativeOverlayClose = document.querySelector(
    ".initiativeOverlay .initiativeOverlay-close"
  );

if (initiativeButton) {
  initiativeButton.addEventListener("click", () => {
    initiativeOverlay.classList.add("is-active");
  });
  initiativeOverlayBGClose.addEventListener("click", () => {
    initiativeOverlay.classList.remove("is-active");
  });
  initiativeOverlayClose.addEventListener("click", () => {
    initiativeOverlay.classList.remove("is-active");
  });
}

// initiativeOverlay
let initiativeItems = document.querySelectorAll(".init");
if (initiativeItems) {
  initiativeItems.forEach((item, index) => {
    initiativeItems[index].addEventListener("click", () => {
      initiativeOverlay.classList.add("is-active");
    });
  });
}

// joinOverlay
let joinButton = document.getElementById("joinButton"),
  joinOverlay = document.getElementById("joinOverlay"),
  joinOverlayBGClose = document.querySelector(".joinOverlay .joinOverlay-bg"),
  joinOverlayClose = document.querySelector(".joinOverlay .joinOverlay-close");
const body = document.querySelector("body");

if (joinButton) {
  joinButton.addEventListener("click", () => {
    joinOverlay.classList.add("is-active");
  });
  joinOverlayBGClose.addEventListener("click", () => {
    joinOverlay.classList.remove("is-active");
  });
  joinOverlayClose.addEventListener("click", () => {
    joinOverlay.classList.remove("is-active");
  });
}

let joinItems = document.querySelectorAll(".init2");
if (joinItems) {
  joinItems.forEach((item, index) => {
    joinItems[index].addEventListener("click", () => {
      joinOverlay.classList.add("is-active");
    });
  });
}

// successOverlay
let successButton = document.getElementById("successButton"),
  successOverlay = document.getElementById("successOverlay"),
  successOverlayBGClose = document.querySelector(
    ".successOverlay .successOverlay-bg"
  ),
  successOverlayClose = document.querySelector(
    ".successOverlay .successOverlay-close"
  );

if (successButton) {
  successButton.addEventListener("click", () => {
    successOverlay.classList.add("is-active");
  });
  successOverlayBGClose.addEventListener("click", () => {
    successOverlay.classList.remove("is-active");
  });
  successOverlayClose.addEventListener("click", () => {
    successOverlay.classList.remove("is-active");
  });
}

let successItems = document.querySelectorAll(".init3");
if (successItems) {
  successItems.forEach((item, index) => {
    successItems[index].addEventListener("click", () => {
      joinOverlay.classList.add("is-active");
    });
  });
}

sal();


var inputs = document.querySelectorAll(".file-input");

for (var i = 0, len = inputs.length; i < len; i++) {
  customInput(inputs[i]);
}

function customInput(el) {
  const fileInput = el.querySelector('[type="file"]');
  const label = el.querySelector("[data-js-label]");

  fileInput.onchange = fileInput.onmouseout = function () {
    if (!fileInput.value) return;

    var value = fileInput.value.replace(/^.*[\\\/]/, "");
    el.className += " -chosen";
    label.innerText = value;
  };
}