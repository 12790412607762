// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Thumbs } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Thumbs]);

const defaultSliderOptions = {
  slidesPerView: 1,
  spaceBetween: 8,

  breakpoints: {
    1200: {
      slidesPerView: 5,
      spaceBetween: 15,
    },
    1000: {
      slidesPerView: 4,
    },
    800: {
      slidesPerView: 3,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    390: {
      slidesPerView: 2,
      spaceBetween: 8,
    },
    300: {
      slidesPerView: 2,
    },
  },
};

const defaultSliders = document.querySelectorAll(".defaultSlider-js");
if (defaultSliders) {
  defaultSliders.forEach((slider) => {
    if (slider.classList.contains("is-3")) {
      new Swiper(slider.querySelector(".swiper-container"), {
        slidesPerView: 1,
        spaceBetween: 15,
        speed: 500,
        breakpoints: {
          1170: {
            slidesPerView: 3,
          },
          800: {
            slidesPerView: 2,
          },
          575: {
            slidesPerView: 1,
          },
        },
        navigation: {
          nextEl: slider.querySelector(".defaultSlider-js-next"),
          prevEl: slider.querySelector(".defaultSlider-js-prev"),
        },
      });
    } else {
      new Swiper(slider.querySelector(".swiper-container"), {
        ...defaultSliderOptions,
        navigation: {
          nextEl: slider.querySelector(".defaultSlider-js-next"),
          prevEl: slider.querySelector(".defaultSlider-js-prev"),
        },
      });
    }
  });
}

const galleryThumbs = new Swiper(".gallery-thumbs", {
  direction: "vertical",
  spaceBetween: 10,
  slideToClickedSlide: true,
  slidesPerView: "auto",
});

const galleryTop = new Swiper(".gallery-top", {
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: galleryThumbs,
  },
});
const galleryThumbs2 = new Swiper(".gallery-thumbs2", {
  direction: "vertical",
  spaceBetween: 10,
  slideToClickedSlide: true,
  slidesPerView: "auto",
});

const galleryTop2 = new Swiper(".gallery-top2", {
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: galleryThumbs2,
  },
});

new Swiper("#mainSlider", {
  slidesPerView: 1,
  spaceBetween: 20,
  watchSlidesVisibility: true,
  autoplay: {
    delay: 4000,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

new Swiper("#explore", {
  slidesPerView: 6,
  loop: true,
  // autoplay: {
  //   delay: 2000,
  // },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 6,
      spaceBetween: 30,
    },
  },
  navigation: {
    nextEl: ".explore-swiper-next",
    prevEl: ".explore-swiper-prev",
  },
});

new Swiper("#dry", {
  slidesPerView: 1,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const swiperDevelop2 = new Swiper("#develop-carousel-2", {
  slidesPerView: 1,
  watchSlidesProgress: true,
});

const swiperDevelop1 = new Swiper("#develop-carousel-1", {
  slidesPerView: 1,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  thumbs: {
    swiper: swiperDevelop2,
  },
});

new Swiper("#brands", {
  slidesPerView: 3,
  // autoplay: {
  //   delay: 2000,
  // },

  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  navigation: {
    nextEl: ".brands-nav-next",
    prevEl: ".brands-nav-prev",
  },
});

new Swiper("#special-deals", {
  slidesPerView: 4,
  loop: true,
  // autoplay: {
  //   delay: 2000,
  // },

  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
  navigation: {
    nextEl: ".special-nav-next",
    prevEl: ".special-nav-prev",
  },
});

new Swiper("#productGallery", {
  slidesPerView: 1,
  navigation: {
    nextEl: ".product-nav-next",
    prevEl: ".product-nav-prev",
  },
});
